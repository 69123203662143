<template>
  <div
    v-if="$can('read', 'ShareTarget')"
    style="height: inherit"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Filters -->
      <share-target-list-filters
        :collapsed-filter.sync="collapsedFilter"
        :name-filter.sync="nameFilter"
        @reset-filter="resetFilter"
      />

      <!-- Table Container Card -->
      <b-card
        no-body
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <label>{{ $t('Entries') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0">
                <b-button
                  v-if="$can('create', 'ShareTarget')"
                  variant="primary"
                  class="w-50"
                  @click="addShareTargetForm"
                >
                  {{ `${$t('Add')} ${$t('Share Target')}` }}
                </b-button>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1 ml-1"
                  :placeholder="`${$t('Search')}...`"
                />
              </div>
            </b-col>
          </b-row>

        </div>
        <b-table
          ref="refShareTargetListTable"
          :items="getListShareTarget"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          hover
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
          selectable
          select-mode="multi"
        >
          <!-- Column: Name/Id -->
          <template #cell(name)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Name') }} »
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.name }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('ID') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-info` : `light-info` "
                      class="text-capitalize"
                    >
                      {{ data.item.id }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: JSON Share/Target/Link -->
          <template #cell(json)="data">
            <!-- Share JSON -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Share JSON') }} »
                  </span>

                  <span :style="(data.item.share_json !== '') ? 'color: #ff0000;' : 'color: #000000;'">
                    <feather-icon
                      :icon="(data.item.share_json !== '') ? 'FileTextIcon' : 'FileIcon'"
                      class="cursor-pointer mr-1"
                      size="16"
                      @click="onShowShareJSON(data)"
                    />
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Target JSON -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Target JSON') }} »
                  </span>

                  <span :style="(data.item.share_message !== '') ? 'color: #ff0000;' : 'color: #000000;'">
                    <feather-icon
                      :icon="(data.item.share_message !== '') ? 'FileTextIcon' : 'FileIcon'"
                      class="cursor-pointer mr-1"
                      size="16"
                      @click="onShowTargetJSON(data)"
                    />
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Link -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Link') }} »
                  </span>
                  <div>
                    <b-link
                      :href="data.item.link"
                      target="_blank"
                    >
                      {{ data.item.link }}
                    </b-link>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Status/DateTime -->
          <template #cell(status)="data">
            <!-- Status -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Status') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-${isStatusVariant(data.item.status)}` : `light-${isStatusVariant(data.item.status)}` "
                      class="text-capitalize"
                    >
                      {{ $t(isStatusToText(data.item.status)) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Date/Time -->
            <div
              v-if="data.item.date_time_show !== undefined"
              style="margin-top: 0.3rem;"
            >
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Date/Time') }} »
                  </span>
                  <span style="margin-right: 0.4rem;">
                    <feather-icon
                      icon="CalendarIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-secondary` : `light-secondary` "
                      class="text-capitalize"
                    >
                      {{ data.item.date_show }}
                    </b-badge>
                  </span>
                  <span style="margin-left: 0.6rem;margin-right: 0.4rem;">
                    <feather-icon
                      icon="ClockIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-secondary` : `light-secondary` "
                      class="text-capitalize"
                    >
                      {{ data.item.time_show }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <feather-icon
                v-if="$can('write', 'ShareTarget')"
                :id="`share-target-row-${data.item.id}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer mr-1"
                size="16"
                @click="editShareTargetForm(data)"
              />
              <b-tooltip
                v-if="$can('write', 'ShareTarget')"
                :title="`${$t('Edit')} ${$t('Share Target')}`"
                :target="`share-target-row-${data.item.id}-edit-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="left"
              />

              <feather-icon
                v-if="$can('read', 'ShareTarget')"
                :id="`share-target-row-${data.item.id}-view-icon`"
                icon="EyeIcon"
                size="16"
                class="cursor-pointer mr-1"
                @click="viewShareTargetForm(data)"
              />
              <b-tooltip
                v-if="$can('read', 'ShareTarget')"
                :title="`${$t('View')} ${$t('Share Target')}`"
                :target="`share-target-row-${data.item.id}-view-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="left"
              />

              <feather-icon
                v-if="$can('delete', 'ShareTarget')"
                :id="`share-target-row-${data.item.id}-delete-icon`"
                icon="TrashIcon"
                variant="light-success"
                size="16"
                class="cursor-pointer mr-1"
                @click="deleteShareTargetForm(data)"
              />
              <b-tooltip
                v-if="$can('delete', 'ShareTarget')"
                :title="`${$t('Delete')} ${$t('Share Target')}`"
                :target="`share-target-row-${data.item.id}-delete-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="right"
              />

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalShareTargets"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <!-- Form -->
      <share-target-form
        v-model="shallShowShareTargetFormModal"
        :share-target-data="shareTargetData"
        :type-share-target-form="typeShareTargetForm"
        @share-target-form-update="updateShareTargetForm"
        @discard-share-target-form="discardShareTargetForm"
      />

      <!-- Popup -->
      <share-target-popup
        v-model="shallShowShareTargetPopup"
        :popup-data="popupData"
        :title-header-popup="titleHeaderPopup"
        @share-target-popup-update="updateShareTargetPopup"
        @discard-share-target-popup="discardShareTargetPopup"
      />

    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,
  BTable, BButton,
  BBadge, BPagination, BTooltip, BListGroup, BListGroupItem,
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import tableShareTargetList from './tableShareTargetList'
import storeShareTarget from './storeShareTarget'
import ShareTargetForm from './ShareTargetForm.vue'
import ShareTargetListFilters from './ShareTargetListFilters.vue'
import storeSetting from '../setting/storeSetting'
import ShareTargetPopup from './ShareTargetPopup.vue'

export default {
  components: {
    ShareTargetListFilters,
    ShareTargetForm,
    ShareTargetPopup,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BButton,
    BLink,
    BOverlay,

    vSelect,
    FeatherIcon,
  },
  data() {
    return {
      showOverlay: false,
      shareTargetData: {},
      popupData: {},
      dialog: false,
      skin: store.state.appConfig.layout.skin,
      selected: [],
      checkSelected: false,
      collapsedFilter: true,
    }
  },
  Computed: {
    checkSelected() {
      return !this.checkSelected
    },
  },
  mounted() {
    this.getDefaultShareTarget()
    this.locale = this.$i18n.locale
  },
  methods: {
    getDefaultShareTarget() {
      this.showOverlay = true
      store
        .dispatch('sharetarget-store/getDefaultShareTarget', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.shareTargetData = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = true
        })
    },
    addShareTargetForm() {
      this.showOverlay = true
      store
        .dispatch('sharetarget-store/getDefaultShareTarget', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.shareTargetData = response.data
          this.typeShareTargetForm = 'Add'
          this.shallShowShareTargetFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editShareTargetForm(data) {
      this.showOverlay = true
      store
        .dispatch('sharetarget-store/getShareTarget', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.shareTargetData = response.data
          this.typeShareTargetForm = 'Edit'
          this.shallShowShareTargetFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    viewShareTargetForm(data) {
      this.showOverlay = true
      store
        .dispatch('sharetarget-store/getShareTarget', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.shareTargetData = response.data
          this.typeShareTargetForm = 'View'
          this.shallShowShareTargetFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deleteShareTargetForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('sharetarget-store/deleteShareTarget', { id: data.item.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updateShareTargetForm() {
      this.refetchData()
    },
    discardShareTargetForm() {
      this.refetchData()
    },
    resetFilter() {
      this.searchQuery = ''
      this.nameFilter = ''
      const filterList = JSON.parse(localStorage.getItem('filterShareTargetList'))
      if (filterList !== null) {
        this.perPage = (filterList.perPage !== null) ? filterList.perPage : '5'
        this.currentPage = (filterList.page !== null) ? filterList.page : '1'
        this.sortBy = (filterList.sortBy !== null) ? filterList.sortBy : 'id'
        this.isSortDirDesc = (filterList.sortDesc !== null) ? filterList.sortDesc : 'DESC'
      }
    },
    onShowShareJSON(data) {
      this.showOverlay = true
      store
        .dispatch('sharetarget-store/getShareTarget', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.popupData.message = response.data.share_json
          this.titleHeaderPopup = this.$i18n.t('Share JSON')
          this.shallShowShareTargetPopup = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    onShowTargetJSON(data) {
      this.showOverlay = true
      store
        .dispatch('sharetarget-store/getShareTarget', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.popupData.message = response.data.target_json
          this.titleHeaderPopup = this.$i18n.t('Target JSON')
          this.shallShowShareTargetPopup = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    updateShareTargetPopup() {
    },
    discardShareTargetPopup() {
    },
  },
  setup() {
    const shallShowShareTargetFormModal = ref(false)
    const typeShareTargetForm = ref('')

    const shallShowShareTargetPopup = ref(false)
    const titleHeaderPopup = ref('')

    // Register module
    if (!store.hasModule('sharetarget-store')) store.registerModule('sharetarget-store', storeShareTarget)
    if (!store.hasModule('setting-store')) store.registerModule('setting-store', storeSetting)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('sharetarget-store')) store.unregisterModule('sharetarget-store')
      if (store.hasModule('setting-store')) store.unregisterModule('setting-store')
    })

    const {
      getListShareTarget,
      tableColumns,
      perPage,
      currentPage,
      totalShareTargets,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refShareTargetListTable,
      refetchData,

      // UI
      isStatusVariant,
      isStatusToText,
      textFirstUpper,

      nameFilter,
    } = tableShareTargetList()

    const filterList = JSON.parse(localStorage.getItem('filterShareTargetList'))
    if (filterList !== null) {
      searchQuery.value = (filterList.searchQuery !== null && filterList.searchQuery !== undefined) ? filterList.searchQuery : ''
      perPage.value = (filterList.perPage !== null && filterList.perPage !== undefined) ? filterList.perPage : ''
      currentPage.value = (filterList.page !== null && filterList.page !== undefined) ? filterList.page : ''
      sortBy.value = (filterList.sortBy !== null && filterList.sortBy !== undefined) ? filterList.sortBy : ''
      isSortDirDesc.value = (filterList.sortDesc !== null && filterList.sortDesc !== undefined) ? filterList.sortDesc : ''
      nameFilter.value = (filterList.name !== null && filterList.name !== undefined) ? filterList.name : ''
    }

    return {
      shallShowShareTargetFormModal,
      typeShareTargetForm,

      shallShowShareTargetPopup,
      titleHeaderPopup,

      getListShareTarget,
      tableColumns,
      perPage,
      currentPage,
      totalShareTargets,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refShareTargetListTable,
      refetchData,

      // UI
      isStatusVariant,
      isStatusToText,
      textFirstUpper,

      nameFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.share-target-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/tools/sharetarget.scss";
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>
