import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultSetting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/tools/get_default_setting', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListSetting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/get_list_setting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSetting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/get_setting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSetting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/add_setting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editSetting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/edit_setting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSetting(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/delete_setting', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    settingOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/tools/setting_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
